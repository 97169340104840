div.column_five {
    width: 100%;
    padding: 50px 10px;
    column-gap: 25px;
    row-gap: 25px;
    display: flex;
    justify-content: center;
    flex-direction: row;
}

div.column_five>img, div.column_five>picture, div.column_five>picture>img {
    max-width: 150px;
    max-height: 150px;
    min-width: 100px;
    min-height: 100px;
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 860px) {
    div.column_five {
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
    }
}